import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { storage, fireStore } from '../../firebase';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Input, Button, Form, Col, Row, Icon, Modal, message, notification, Switch } from 'antd';

var QRCode = require('qrcode.react');

let FormItem = Form.Item;

function qrForm(props) {

    const [placeholder, setPlaceholder] = useState(null)
    const [value, setValue] = useState(null)
    const [qr, setQr] = useState(null)

    const [fileUrl, setFileUrl] = useState(null)

    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [position, setPosition] = useState(null)
    const [loading, setLoading] = useState(false)

    const [openInfoModal, setOpenInfoModal] = useState(false)
    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
        setPlaceholder("'Placeholder' set via React Hooks useState and useEffect")
    }, [])

    let infoModal = () => {
        console.log('info modal opened');
    }

    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(qr)
          .then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 2000); // Remove message after 2 seconds
          })
          .catch(err => {
            setCopySuccess('Failed to copy!');
          });
      };

    let submit = async (e) => {
        setLoading(true)
        e.preventDefault();
        props.form.resetFields()
        await setQr("http://search.google.com/local/writereview?placeid=" + value.value.place_id);

        var canvas = document.getElementById("mycanvas");
        var img = canvas.toDataURL("image/png");

        console.log(img);

        var link = document.createElement("a");
        link.download = "image.png";

        canvas.toBlob(async function (blob) {
            link.href = URL.createObjectURL(blob);
            const storageRef = storage.ref();
            const fileRef = storageRef.child(lastName);
            await fileRef.put(blob);

            fileRef.getDownloadURL().then((url) => {
                setFileUrl(url)
                let functionCall = firebase.functions().httpsCallable('helloWorld');
                console.log('test!!!');
                functionCall({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    business: value.label,
                    position: position,
                    url: "http://search.google.com/local/writereview?placeid=" + value.value.place_id,
                    attachment: { fileName: lastName + Date.now(), path: url },
                    QRCode: "http://search.google.com/local/writereview?placeid=" + value.value.place_id,
                }).then(() => {
                    fireStore.collection("identities").doc(lastName + ' - ' + Date.now()).set({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        business: value.label,
                        position: position,
                        entered: Date.now(),
                        QRCode: "http://search.google.com/local/writereview?placeid=" + value.value.place_id,
                    })
                }).then(function (result) {
                    message.success("Thank you! Your QR code has been sent to " + email)
                    setValue(null)
                    setLoading(false)
                }).catch(function (error) {
                    console.log(error)
                    setLoading(false)
                });

                let adminEmail = firebase.functions().httpsCallable('adminEmail');
                adminEmail({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    business: value.label,
                    position: position,
                    url: "http://search.google.com/local/writereview?placeid=" + value.value.place_id,
                    attachment: { fileName: lastName + Date.now(), path: url },
                    QRCode: "http://search.google.com/local/writereview?placeid=" + value.value.place_id,
                })

            })
            
        }, 'image/png');

        console.log(link.href);
        link.click();

        // const file = document.write('<img src="'+img+'"/>');
        // const storageRef = storage.ref();
        // const fileRef = storageRef.child(lastName);
        // await fileRef.put(file);
        // setFileUrl(await fileRef.getDownloadURL());

        console.log(fileUrl);

        // let functionCall = firebase.functions().httpsCallable('helloWorld');
        // functionCall({
        //     firstName: firstName,
        //     lastName: lastName,
        //     email: email,
        //     business: value.label,
        //     QRCode: "http://search.google.com/local/writereview?placeid=" + value.value.place_id,
        // }).then(() => {
        //     fireStore.collection("identities").doc(lastName + ' - ' + Date.now()).set({
        //         firstName: firstName,
        //         lastName: lastName,
        //         email: email,
        //         business: value.label,
        //         entered: Date.now(),
        //         QRCode: "http://search.google.com/local/writereview?placeid=" + value.value.place_id,
        //     })
        //     .then(function() {
        //         console.log("Document successfully written!");
        //     })
        // }).then(function (result) {
        //     message.success("Thank you! Your QR code has been sent to " + email)
        //     setLoading(false)
        // }).catch(function (error) {
        //     console.log(error)
        //     setLoading(false)
        // });
        setLoading(false)
    }

    let { getFieldDecorator } = props.form;

    let textChange = (e) => {

        switch (e.target.id) {
            case 'firstName':
                setFirstName(e.target.value);
                break;
            case 'lastName':
                setLastName(e.target.value);
                break;
            case 'email':
                setEmail(e.target.value);
                break;
            case 'position':
                setPosition(e.target.value);
                break;
            default:
                alert('error in textChange function')
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 21,
                offset: 0,
            },
        },
    };

    return (
        <div style={{ marginTop: '3em' }}>
            <Row>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }} className="userHome" >

                    
                    <Form onSubmit={(e) => (submit(e))} className="qrForm" {...formItemLayout} hideRequiredMark={true}>
                        
                        <div className='instruction'>
                            <b><h3>Please Fill Out Form Below</h3></b>
                            <p>At the bottom, enter your business's name.  You'll be prompted with google results that match what you've entered.  
                                Select your business and click submit. You'll see the QR code that takes people to your business's Google Review.  
                                The QR Code will also be emailed to you for printing or forwarding.  </p>
                        </div>

                        <Form.Item label="First Name">
                            {getFieldDecorator('firstName', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your first name',
                                    },
                                ],
                            })(<Input
                                onChange={textChange}
                            />)}
                        </Form.Item>

                        {/* <FormItem>
                            {getFieldDecorator('firstName', {
                                rules: [{ required: true, message: 'Please enter your first name' }, { max: 35 }]
                            })(
                                <Input
                                    onChange={textChange}
                                    placeholder="First Name"
                                />
                            )}
                        </FormItem> */}

                        <Form.Item label="Last Name">
                            {getFieldDecorator('lastName', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your last name',
                                    },
                                ],
                            })(<Input
                                onChange={textChange}
                            />)}
                        </Form.Item>

                        {/* <FormItem>
                            {getFieldDecorator('lastName', {
                                rules: [{ required: true, message: 'Please enter your last name' }, { max: 35 }]
                            })(
                                <Input
                                    onChange={textChange}
                                    placeholder="Last Name"
                                />
                            )}
                        </FormItem> */}

                        <Form.Item label="Email">
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input
                                onChange={textChange}
                            />)}
                        </Form.Item>

                        {/* <FormItem>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please enter employee email' }, { type: 'email', message: 'The input is not valid E-mail' }]
                            })(
                                <Input onChange={textChange} placeholder="Email" type='email' />
                            )}
                        </FormItem> */}

                        <Form.Item label="Your Job Title">
                            {getFieldDecorator('position', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Please input your job title',
                                    },
                                ],
                            })(<Input
                                onChange={textChange}
                            />)}
                        </Form.Item>

                        {/* <FormItem>
                            {getFieldDecorator('position', {
                                rules: [{ required: true, message: 'Your job title or position' }, { max: 35 }]
                            })(
                                <Input
                                    onChange={textChange}
                                    placeholder="Job Title"
                                />
                            )}
                        </FormItem> */}

                

                            <Form.Item label="Your Business Name">
                                {getFieldDecorator('position', {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Please input your job title',
                                        },
                                    ],
                                })(<GooglePlacesAutocomplete
                                    onLoadFailed={(error) => (
                                        console.error("Could not inject Google script", error)
                                    )}

                                    selectProps={{
                                        value,
                                        onChange: setValue,
                                        placeholder: ""
                                    }}

                                    autocompletionRequest={{
                                        bounds: [
                                            { lat: 50, lng: 50 },
                                            { lat: 100, lng: 100 }
                                        ],
                                        componentRestrictions: {
                                            country: ['us', 'ca', 'uy'],
                                        }
                                    }}
                                />)}
                            </Form.Item>
                     


                            <Form.Item {...tailFormItemLayout}>

                            <Button style={{ float: 'right', marginTop: '1.5em' }} loading={loading} htmlType="submit" >Submit</Button>

                        </Form.Item>
                    
                    </Form>
                    <p className="info" onClick={() => setOpenInfoModal(true)}>Click HERE to see how we use your info</p>
                </Col>

                {qr ?
                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }} style={{ textAlign: 'center', padding: '3em' }}>
                        <p>Thank you for using Google Reviews QR Code Generator! Here is your QR code.</p>
                        <p>You will also receive an email shortly with the same QR code in a downloadable version.</p>
                        <p>Here is the Google Review Link that has been created for you:</p>
                        <div style={{ marginBottom: '10px' }}>
                            <input value={qr} readOnly style={{ width: '100%' }} />
                        </div>
                        <div>
                            <button onClick={copyToClipboard} style={{ marginBottom: '20px' }}>Click here to copy</button>
                        </div>
                        {copySuccess && <p style={{ color: 'green' }}>{copySuccess}</p>}
                        <div><QRCode id="mycanvas" value={qr} /></div>
                    </Col>
                    : null}
            </Row>

            

            <Modal
                title='How We Use Your Info'
                visible={openInfoModal}
                closable={false}
                footer={null}
            //width={1000}
            >
                <p>

                When you visit the Site, we automatically collect certain information about your device, 
including information about your web browser, IP address, time zone, and some of the cookies 
that are installed on your device. Additionally, as you browse the Site, we collect information 
about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this 
automatically-collected information as “Device Information”

                </p>
                <Button onClick={() => setOpenInfoModal(false)}>Close</Button>
            </Modal>

        </div>
    );
}

const UserHome = Form.create()(qrForm);

export default UserHome; 